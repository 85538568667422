import * as React from "react"
import HeaderHead from "./HeaderHead";
import Footer from "./Footer";

const SiteContainer = ({classes = [], fluid = false, children, header = true}) => {
    return (
        <>
            {header ? <HeaderHead /> : ""}
            {children}
            <Footer />
        </>
    )
}

export default SiteContainer;