import * as React from "react"
import Container from "react-bootstrap/Container";
import { Link } from "gatsby"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import twitter_icon from "../images/icons/social/twitter.svg"


const Footer = ({classes = [], fluid = false, children}) => {
    return (
        <footer className="py-8 py-md-11 bg-black">
            <Container fluid={fluid}>
                <Row>
                    <Col md={4}>
                        <a className="text-gray-700 navbar-brand" href="/">Firstparty</a>
                        <ul className="list-unstyled list-inline list-social mb-6 mb-md-0">
                            <li className="list-inline-item list-social-item me-3">
                                <a href="https://twitter.com/firstpartyhq" className="text-decoration-none">
                                    <img src={twitter_icon} className="list-social-icon"
                                         alt="@firstpartyhq on Twitter" />
                                </a>
                                <small>Copyright 2021 First Party Labs, Inc. All Rights Reserved. </small>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                        <h6 className="fw-bold text-uppercase text-gray-700">
                            Solutions
                        </h6>
                        <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                            <li className="mb-3">
                                <Link to="/solutions/attribution/" className="text-reset">
                                    Attribution
                                </Link>
                            </li>
                            <li className="mb-3">
                                <Link to="/solutions/audiences/" className="text-reset">
                                    Audiences
                                </Link>
                            </li>
                            <li className="mb-3">
                                <Link to="/solutions/analytics/" className="text-reset">
                                    Analytics
                                </Link>
                            </li>
                            <li className="mb-3">
                                <Link to="/solutions/automation/" className="text-reset">
                                    Automation
                                </Link>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                        <h6 className="fw-bold text-uppercase text-gray-700">
                            Knowledge
                        </h6>
                        <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                            <li className="mb-3">
                                <Link to="/workflows/" className="text-reset">
                                    Workflows
                                </Link>
                            </li>
                            <li className="mb-3">
                                <Link to="/pricing/" className="text-reset">
                                    Pricing
                                </Link>
                            </li>
                            <li className="mb-3">
                                <Link to="/docs/introduction" className="text-reset">
                                    Documentation
                                </Link>
                            </li>
                            <li className="mb-3">
                                <Link to="/docs/api/reference" className="text-reset">
                                    API Reference
                                </Link>
                            </li>
                            <li className="mb-3">
                                <a href="mailto:support@firstpartyhq.com" className="text-reset">
                                    Support
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                        <h6 className="fw-bold text-uppercase text-gray-700">
                            Company
                        </h6>
                        <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                        <li className="mb-3">
                                <a href="/careers" className="text-reset">
                                    Jobs
                                </a>
                            </li>
                            <li className="mb-3">
                                <Link to="/news" className="text-reset">
                                    News
                                </Link>
                            </li>
                            <li className="mb-3">
                                <Link to="/contact" className="text-reset">
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                        <h6 className="fw-bold text-uppercase text-gray-700">
                            Legal
                        </h6>
                        <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                            <li className="mb-3">
                                <Link to="/terms" className="text-reset">
                                    Terms
                                </Link>
                            </li>
                            <li className="mb-3">
                                <Link to="/privacy" className="text-reset">
                                    Privacy
                                </Link>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row className="mt-10">
                    <Col>
                        <p className="small text-muted">Copyright &copy; {(new Date()).getFullYear()} First Party Labs, Inc. All Rights Reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;